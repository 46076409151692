import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

function useCurrentRouteName() {
  const route = useRoute()

  const activeRoute = ref(route.name)

  watch(
    () => route.name,
    newRouteName => {
      if (newRouteName) activeRoute.value = newRouteName
    }
  )

  return activeRoute
}

export default useCurrentRouteName
