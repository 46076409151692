import { LocationQuery } from 'vue-router'

import { PaginationParams } from '@/models/api'
import { toLocationQuery } from '@/helpers/query'

const DEFAULT_PAGINATION: PaginationParams = {
  page: 1,
  perPage: 10,
  sortDirection: 'desc',
}

export const getDefaultPagination = (): PaginationParams => ({
  ...DEFAULT_PAGINATION,
})

export const getDefaultPaginationQuery = (): LocationQuery =>
  toLocationQuery({ ...DEFAULT_PAGINATION })
